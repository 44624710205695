#table-results{
    
    width: 100%;
    table-layout: fixed;
}

#table-results td{
        
    background-color:#2f343e;
    padding:10px;   
    word-wrap: break-word;
    white-space:pre-wrap;
}

#table-results th{
    
    padding: 5px 10px;
    background-color: #191e29;
    color: #a5a9b9;
    text-align:left;
    
}

.selected-substance{
    
    background-color:#434852 !important;
    
}

.selected-substance a{
    
    color:#79e2ff
}

section{
    
    padding: 14px 14px;
    border: 1px solid #dbdbdb;
    color: #343434;
    /* border-top-width: 1px; */
    background-color: #c7c7c7;
    margin: 5px;
    border-radius: 5px
}


.details{
    
    padding-top:15px;
}

.details-title{ 
    
    text-transform: capitalize;
}

.details-content{
    
    margin-top: 5px;
    padding: 10px;
    background-color: rgb(181 181 181);
    border-radius:2px;
}


.table-details{
    
    padding-top:15px;
    min-width:100%
}

.table-details th{
    
   background-color: #a3a3a3 !important;
    color: #464646!important;
}

.table-details td{
    
    background-color:#b5b5b5 !important;
}

#ifra-categories-tables{
    
    display:flex
}

.section-title{
    
    width:90%;
    text-align:left;
}