/* Loader */
.centered-loader{
    
    width:25px;
    margin:auto;

}

.lds-dual-ring {
  display: inline-block;  
}

.lds-dual-ring:after {
  content: "";
    display: block;
    width: 22px;
    height: 22px;
    /* margin: 8px; */
    border-radius: 50%;
    border: 3px solid #fff;
    border-color: #6c727f transparent #fff transparent;
    animation: lds-dual-ring 0.5s cubic-bezier(0.4, 0, 0.12, 1.17) infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}