html{
    
    background-color: #282c34;
}

body{
    font-size:0.9em;
}

.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(215,215,215);
  padding-top:150px
}

a{
       
  color: #bcd3d9;
  text-decoration:none;
}

a:hover{
    
    color:#79e2ff
}
button{
    
    background-color: #efefef;
    color: #343434;
    padding: 10px 35px;
    border-radius: 5px;
    border: 1px solid black;
}

.clickable{
    
    cursor:pointer;
}

#search-box{
    
    padding:8px;
    font-size:1.2em
}

.result{
    
    margin:10px;
}

.result:hover{
    
    color:#778db9;
}

.result-details{
    
    text-align: left;
    padding: 15px;
    background-color: #31353d;
    border: 1px solid #3e4553;
    border-radius: 5px;
    
}

#error{
    
    background-color: #4c0a0a;
    color: #f79e9e;
    padding: 8px;
    margin-top: 15px; 
    min-width: 150px;
}